const routes = [
  {
    index: true,
    label: 'Hung X. Tran',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];
// {
//   label: 'Stats',
//   path: '/stats',
// },

export default routes;
